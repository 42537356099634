import Component from "./Component";
import * as THREE from "three";
import LiveStreamClient from "./LiveStreamClient";
import { PositionalAudioHelper } from "three/examples/jsm/helpers/PositionalAudioHelper";
import { RAYCAST_EXCLUDE_LAYER } from "../core/Scene";

export default class LiveStreamScreen extends Component {
    constructor(uuid, scene, audioListener, width, height, poster) {
        super();

        this.uuid = uuid;
        this.width = width || 16.0;
        this.height = height || 9.0;

        const RAYCAST_EXCLUDE_LAYER = 31;

        const video = document.createElement("video");
        video.style.display = "none";
        video.id = this.uuid;
        video.setAttribute("webkit-playsinline", "webkit-playsinline");
        video.setAttribute("playsinline", "playsinline");
        const root = document.getElementById("root");
        root.appendChild(video);

        this.loader = new THREE.TextureLoader();

        const texture = new THREE.VideoTexture(video);
        this.videoTexture = texture;

        const material = new THREE.ShaderMaterial({
            fragmentShader: this.fragmentShader(),
            vertexShader: this.vertexShader(),
            uniforms: {
                tex: { type: "t", value: texture },
                enabled: { value: false },
            },
            transparent: true,
            side: THREE.DoubleSide
        });

        this.videoMaterial = material;

////////////////////////////////////////////////////added by chris - needs update
////////////////////////////////////////////////////
//         this.loader.load(poster, (texture) => {
//             // const material = new THREE.MeshBasicMaterial({
//             //     map: texture
//             // });
//             const material = new THREE.ShaderMaterial({
//                 fragmentShader: this.fragmentShader(),
//                 vertexShader: this.vertexShader(),
//                 uniforms: { tex: { type: "t", value: texture } },
//             });
//             this.posterMaterial = material;
//             this.board.material = material;
//             this.board.material.needsUpdate = true;
//         });
////////////////////////////////////////////////////added by chris
////////////////////////////////////////////////////


        // const planeGeometry = new THREE.PlaneGeometry(3.2, 1.8);
        const planeGeometry = new THREE.PlaneGeometry(this.width,this.height);
        this.board = new THREE.Mesh(planeGeometry, material);
        // this.board.traverse((x) => {
        //         x.layers.disableAll()
        //         x.layers.enable(RAYCAST_EXCLUDE_LAYER);
        //         })

        //todo why is this static?
        // const geometry = new THREE.PlaneGeometry( 16.0, 9.0 );
        const geometry = new THREE.PlaneGeometry( this.width, this.height );
        const screen = new THREE.Mesh( geometry, material );
        screen.traverse((x) => {
            x.layers.disableAll()
            x.layers.enable(RAYCAST_EXCLUDE_LAYER);
        })
        this.screen = screen;
        scene.add(screen);

        const positionalAudio = new THREE.PositionalAudio(audioListener);
        // positionalAudio.play();
        // positionalAudio.setRefDistance(8);
        // positionalAudio.setMaxDistance(50);
        // positionalAudio.setVolume(10.0);
        // // positionalAudio.setRolloffFactor(2.5);
        // positionalAudio.setDirectionalCone( 270, 300, 0.1 );
        // this.positionalAudio = positionalAudio;

        // const helper = new PositionalAudioHelper(positionalAudio, 2);
        // helper.layers.disableAll();
        // helper.layers.enable(RAYCAST_EXCLUDE_LAYER);
        // positionalAudio.add(helper);

        // screen.add(positionalAudio);

        video.play();
    }

    vertexShader() {
        return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `;
    }

    fragmentShader() {
        return `
            uniform sampler2D tex;
            uniform bool enabled;
            varying vec2 vUv;

            void main () {
                mediump vec3 inColour = texture2D(tex, vUv).rgb;
                if (!enabled) {
                    gl_FragColor = vec4(inColour, 1.0);
                } else {
                    mediump vec3 green = vec3(0.0, 1.0, 0.0);
                    mediump float a = (length(inColour - green) - 0.5) * 7.0;
                    gl_FragColor = vec4(inColour, a);
                }
            }
        `;
    }

    setPosition(vect) {
        this.screen.position.copy(vect)
    }

    setPlaceholderActive = (active) => {
        this.videoMaterial.uniforms.texture = active ? this.placeholderTexture : this.videoTexture;
        this.videoMaterial.needsUpdate = true;
    };
}
