//todo - various updates need copying over

import * as THREE from "three";
import Multiplayer from "../three-components/MultiplayerSimple";
import LookControlsV2 from "../three-components/LookControlsV2";
import { makeAutoObservable } from "mobx";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Component from "../three-components/Component";
import HDREnvironment from "../three-components/HDREnvironment";
import Heightmap from "../three-components/heightmap";
import MovePad from "../three-components/MovePad";
import Clickable from "../three-components/Clickable";
import Draggable from "../three-components/Draggable";
import Hoverable from "../three-components/Hoverable";
import Whiteboard from "../three-components/Whiteboard3";
import WhiteboardControls from "../three-components/WhiteboardControls";
import Screenshot from "../three-components/Screenshot";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

import classic from "../rooms/classic.json";
import brutalist from "../rooms/brutalist.json";
import artgallery from "../rooms/artgallery.json";
import exhibitionSpace from "../rooms/exhibitionSpace.json";
import moodyArtGallery from "../rooms/moodyArtGallery.json";
import conferenceRoom from "../rooms/conferenceRoom.json";
import natureLounge from "../rooms/natureLounge.json";
import festiverse from "../rooms/festiverse.json";
import syngentaPitchRoom from "../rooms/SyngentaPitchRoom.json";
import littlestTokyo from "../rooms/littlestTokyo.json";
import drawingRoom from "../rooms/drawingRoom.json";
import oasisdigitalgallery from "../rooms/oasisdigitalgallery.json";
import oasisdigitalgallerymobile from "../rooms/oasisdigitalgallerymobile.json";
import mastercard from "../rooms/mastercard.json";
import mastercardgis from "../rooms/mastercard-gis.json";
import mastercardMainStage from "../rooms/mastercardMainStage.json";
import mastercardLobby from "../rooms/mastercardLobby.json";
import ideaPharmaInnoverse from "../rooms/ideaPharmaInnoverse.json";
import gresham from "../rooms/gresham.json";

// import testRoom from "../rooms/test.json";
import speeddating from "../rooms/speeddating.json";


import ImageViewer from "../three-components/ImageViewer";
import ModalTriggerPDF from "../three-components/modal-triggerPDF";
import ModalTriggerURL from "../three-components/modal-triggerURL";
import VideoViewer from "../three-components/VideoViewer";
import VideoAudio from "../three-components/VideoAudio";
import VideoViewerGreenscreen from "../three-components/VideoViewerGreenscreen";
// import React from "react";
import DraggableObject from "../three-components/DraggableObject";
import ScreenObject from "../three-components/Screen";
import ObjectRotator from "../three-components/ObjectRotator";
import App from "./App";
import AvatarBuilder from "../three-components/AvatarBuilder";
import ImageViewerNFT from "../three-components/ImageViewerNFT";
import ImageViewerNFTsimple from "../three-components/ImageViewerNFTsimple";
import ImageViewerNFTsimpleMob from "../three-components/ImageViewerNFTsimpleMob";
import ImageViewerNFTsimpleBio from "../three-components/ImageViewerNFTsimpleBio";
import ImageViewerNFTsimpleBioJoin from "../three-components/ImageViewerNFTsimpleBioJoin";
import ImageViewerNFTsimpleMovie from "../three-components/ImageViewerNFTsimpleMovie";
import ImageViewerNFTsimpleInfo from "../three-components/ImageViewerNFTsimpleInfo";
import ImageViewerNFTsimpleInfoZone from "../three-components/ImageViewerNFTsimpleInfoZone";
import ImageViewerShop from "../three-components/ImageViewerShop";
import LiveStreamScreen from "../three-components/LiveStreamScreen";
import LiveStreamScreenB from "../three-components/LiveStreamScreenB";
import LiveStreamClient from "../three-components/LiveStreamClient";
import LiveStreamClientB from "../three-components/LiveStreamClientB";
import VideoViewerCustom from "../three-components/VideoViewerCustom";
import P5Object from "../three-components/P5Object";
import ExampleSketch from "./p5/ExampleSketch";
import ExampleSketch2 from "./p5/ExampleSketch2";
import ExampleSketch3 from "./p5/ExampleSketch3";
import ExampleSketch4 from "./p5/ExampleSketch4";
import ExampleSketch5 from "./p5/ExampleSketch5";
import ExampleSketch6 from "./p5/ExampleSketch6";
import ExampleSketch7 from "./p5/ExampleSketch7";
import ExampleSketch8 from "./p5/ExampleSketch8";
import ExampleSketch9 from "./p5/ExampleSketch9";
import ExampleSketch10 from "./p5/ExampleSketch10";
import ExampleSketch11 from "./p5/ExampleSketch11";
import ExampleSketch12 from "./p5/ExampleSketch12";
import ExampleSketch13 from "./p5/ExampleSketch13";
import ExampleSketch14 from "./p5/ExampleSketch14";
import ExampleSketch15 from "./p5/ExampleSketch15";
import ExampleSketch16 from "./p5/ExampleSketch16";
import ExampleSketch17 from "./p5/ExampleSketch17";
import MultiplayerTiled from "../three-components/MultiplayerTiled";
import ChatClient from "./ChatClient";
import BelongWelcome from "../three-components/BelongWelcome";
import BelongBillboard from "../three-components/BelongBillboard";
import SpeedDatingClient from "../three-components/SpeedDatingClient";
import BelongService from "./BelongService";
import BelongPicture from "../three-components/BelongPicture";
import BelongInteractiveMap from "../three-components/BelongInteractiveMap";
import CustomFonts from "../utilities/CustomFonts";
export const RAYCAST_EXCLUDE_LAYER = 31, BLOOM_LAYER = 100;
export const LIVE_STREAM_SCREEN_ID = "live-stream-screen"
export const LIVE_STREAM_SCREEN_ID_B = "live-stream-screen-b"
//chris todo add "prettier"
export const ROOMS = [
  classic,
  brutalist,
  artgallery,
  exhibitionSpace,
  moodyArtGallery,
  conferenceRoom,
  natureLounge,
  festiverse,
  syngentaPitchRoom,
  littlestTokyo,
  drawingRoom,
  oasisdigitalgallery,
  oasisdigitalgallerymobile,
  mastercard,
  mastercardgis,
  mastercardMainStage,
  mastercardLobby,
  speeddating,
  ideaPharmaInnoverse,
  gresham
];

export function GetRoomById(id) {
    return ROOMS.find((room) => room.id === id);
}

const bloomVertexShader = `
varying vec2 vUv;

void main() {

vUv = uv;

gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );

}
`

const bloomFragShader = `
uniform sampler2D baseTexture;
uniform sampler2D bloomTexture;

varying vec2 vUv;

void main() {

gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );

}
`

const bloomLayer = new THREE.Layers();
bloomLayer.set( BLOOM_LAYER );

const darkMaterial = new THREE.MeshBasicMaterial( { color: 'black' } );
const materials = {};

const BELONG_MAX_PICTURE_LIMIT = 24;
const BELONG_PICTURE_REFRESH_INTERVAL = 30000;

export default class Scene {

    loading = true;
    hosting = false;

    dragging = false;
    mouse = new THREE.Vector2();
    activeWhiteboard = undefined;
    activeWhiteboardTextOpen = false;

    customCollisionGroup = new THREE.Group();
    activePhotobooth = undefined;
    photoboothCountryOpen = false;
    photoboothTextOpen = false;
    belongExperience = undefined;
    belongBillboard = undefined;
    belongPictures = [];
    inRange = false;

    constructor(mount) {
        makeAutoObservable(this);

        this.preloadFonts();
        this.scene = new THREE.Scene();
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            powerPreference: "high-performance",
        });

        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 200);
        this.camera.layers.enableAll();

        //todo - sanity check lighting
        // https://www.donmccurdy.com/2020/06/17/color-management-in-threejs/

        // standard setup used for three.js example damaged helmet
        this.renderer.toneMapping = THREE.ACESFilmicToneMapping;

        //todo - used for OA
        //// this.renderer.toneMapping = THREE.LinearToneMapping;
        //// this.renderer.toneMappingExposure = 1.25;
        //// this.renderer.outputEncoding = THREE.LinearEncoding;
        /////////
        this.renderer.toneMappingExposure = 1.0;
        this.renderer.outputEncoding = THREE.sRGBEncoding;

/*
        this.renderer.toneMapping = THREE.LinearToneMapping;
        this.renderer.toneMappingExposure = 1.0;
        this.renderer.outputEncoding = THREE.sRGBEncoding;*/

        //not usable here:
        // this.texture.mapping = THREE.EquirectangularReflectionMapping;
        // this.scene.background = texture;
        // this.scene.environment = texture;

        this.renderScene = new RenderPass( this.scene, this.camera );

        this.bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
        this.bloomPass.threshold = 0;
        this.bloomPass.strength = 5;
        this.bloomPass.radius = 0;

        this.bloomComposer = new EffectComposer( this.renderer );
        this.bloomComposer.renderToScreen = false;
        this.bloomComposer.addPass( this.renderScene );
        this.bloomComposer.addPass( this.bloomPass );

        this.finalPass = new ShaderPass(
            new THREE.ShaderMaterial( {
                uniforms: {
                    baseTexture: { value: null },
                    bloomTexture: { value: this.bloomComposer.renderTarget2.texture }
                },
                vertexShader: bloomVertexShader,
                fragmentShader: bloomFragShader,
                defines: {}
            } ), 'baseTexture'
        );
        this.finalPass.needsSwap = true;

        this.finalComposer = new EffectComposer( this.renderer );
        this.finalComposer.addPass( this.renderScene);
        this.finalComposer.addPass( this.finalPass );

        // Temp CUBE
        // const cube = new THREE.Mesh(new THREE.BoxGeometry(), new THREE.MeshBasicMaterial( { color: 'blue' }))
        // cube.layers.enable(BLOOM_LAYER);
        // this.scene.add(cube);
        // cube.position.y = 2;


        //todo -
        // https://github.com/mrdoob/three.js/blob/master/examples/webgl_loader_gltf.html

        this.renderer.setSize(window.innerWidth, window.innerHeight);
        mount.appendChild(this.renderer.domElement);


        // Setup Loading Manager
        this.loadingManager = new THREE.LoadingManager();

        this.loadingManager.onStart = (url, itemsLoaded, itemsTotal) => {
            console.log("Started loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files.");
            this.loading = true;
        };

        this.loadingManager.onLoad = () => {
            console.log("Loading complete!");
            this.loading = false;
        };

        this.loadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
            console.log("Loading file: " + url + ".\nLoaded " + itemsLoaded + " of " + itemsTotal + " files.");
        };

        this.loadingManager.onError = (url) => {
            console.log("There was an error loading " + url);
            this.loadingError = true;
        };

        // Live Stream Client
        this.liveStreamClient = new LiveStreamClient(LIVE_STREAM_SCREEN_ID)
        this.liveStreamClientB = new LiveStreamClientB(LIVE_STREAM_SCREEN_ID_B)
        // Setup Camera and Controls
            this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 200);
            this.camera.layers.enableAll();

        this.controls = new LookControlsV2(this, this.camera, this.renderer, this.scene);
        this.controls.enabled = true;
        this.controls.moveTo(new THREE.Vector3(-10.5, 1.6, 13.5));
        this.controls.postMove = (pos, rot) => {
            if (this.heightmap) {
                this.heightmap.onMove(pos, rot);
            }
        };

        // Setup Extras
        this.screenshot = new Screenshot(this.scene, this.camera, this.renderer);
        this.clock = new THREE.Clock();
        this.raycaster = new THREE.Raycaster();
        this.raycaster.layers.disable(RAYCAST_EXCLUDE_LAYER);
        this.scene.add(this.customCollisionGroup);

        this.listener = new THREE.AudioListener();
        this.camera.add(this.listener);

        const onWindowResize = () => {
            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(window.innerWidth, window.innerHeight);
            this.bloomComposer.setSize( window.innerWidth, window.innerHeight );
            this.finalComposer.setSize( window.innerWidth, window.innerHeight );
        };
        onWindowResize();

        window.addEventListener("resize", onWindowResize, false);

        this.setupLogic();
        this.animate();
    }

    preloadFonts = () => {
        for(const fontName of Object.keys(CustomFonts)) {
            const fontFace = new FontFace(fontName, `url(${CustomFonts[fontName].base}${CustomFonts[fontName].font})`);

            fontFace.load().then((font) => {
                document.fonts.add(font);
                console.log(`${fontName} has loaded!`);
            });
        }
    }

    animate = () => {
        const delta = this.clock.getDelta();

        // Update Multiplayer
        this.multiplayer?.update();

        // Update components
        Component.components.forEach((component) => component.update(delta));

        // Update Heightmap
        if (this.heightmap) {
            const cameraHeight = this.heightmap.update(this.renderer);
            this.controls?.setCameraHeight(cameraHeight);
        }

        // render 3D scene
        //this.renderer.render(this.scene, this.camera);

        this.render();

        requestAnimationFrame(this.animate);
    };

    render() {

        // Normal rendering
        this.renderer.render(this.scene, this.camera);


        // render scene with bloom
        // this.renderBloom( true );

        // render the entire scene, then render bloom scene on top
        // this.finalComposer.render();

    }

    renderBloom( mask ) {
        this.scene.traverse(this.darkenNonBloomed);
        this.bloomComposer.render();
        this.scene.traverse( this.restoreMaterial );
    }

    darkenNonBloomed( obj ) {
        if ( obj.isMesh && bloomLayer.test( obj.layers ) === false ) {
            materials[ obj.uuid ] = obj.material;
            obj.material = darkMaterial;
        }
    }

    restoreMaterial( obj ) {
        if ( materials[ obj.uuid ] ) {
            obj.material = materials[ obj.uuid ];
            delete materials[ obj.uuid ];
        }
    }

    async switchScene(id, send){
        if(send){
            await this.multiplayer.sendCustomChannelMessage("scene", "switchScene", id)
        }

        window.setTimeout(() => {
            const key = encodeURIComponent('sceneOverride');
            const value = encodeURIComponent(id);

            // kvp looks like ['key1=value1', 'key2=value2', ...]
            const kvp = document.location.search.substr(1).split("&");
            let i=0;

            for(; i<kvp.length; i++){
                if (kvp[i].startsWith(key + '=')) {
                    let pair = kvp[i].split('=');
                    pair[1] = value;
                    kvp[i] = pair.join('=');
                    break;
                }
            }

            if(i >= kvp.length){
                kvp[kvp.length] = [key,value].join('=');
            }

            // can return this or...
            let params = kvp.join('&');

            // reload page with new params
            document.location.search = params;
        }, 2000)
    }

    loadRoomById(id) {
        const room = GetRoomById(id);
        if (!room) throw new Error("No room configured with ID: " + id);
        this.loadScene(room);
        return room;
    }

    loadScene = async (config) => {
        const loader = new GLTFLoader(this.loadingManager);

        this.roomData = config;

        if (config.theme)
        {
            App.setAppTheme(config.theme);
        }

        switch (config?.multiplayer_system){
            case "new": {
                console.log("Using new multiplayer");


                let cellSize = undefined;
                let cellOffset = undefined;

                if(config?.multiplayer_settings){
                    if(config?.multiplayer_settings?.cellSize){
                        cellSize = config?.multiplayer_settings?.cellSize;
                    }
                }

                this.multiplayer = new MultiplayerTiled({
                    scene: this,
                    cellOffset,
                    cellSize
                });

                break;
            }
            default: {
                console.log("Using original multiplayer");
                // Setup Multiplayer
                this.multiplayer = new Multiplayer(this.scene, {
                    engine: this,
                    autoLeave: false,
                    onJoin: () => {
                        if (this.multiplayer.rtc_client.remoteUsers.length === 0) {
                            this.hosting = true;
                            this.controls.moveForward(5);
                            this.controls.setRotationFromEuler(
                                new THREE.Euler(
                                    THREE.MathUtils.degToRad(0),
                                    THREE.MathUtils.degToRad(180),
                                    THREE.MathUtils.degToRad(0)
                                )
                            );
                        }
                        this.controls.forceSendTransformUpdate();
                    },
                    onRemoteUserJoin: () => {
                        this.controls.forceSendTransformUpdate();
                    },
                    onLeave: () => {
                        // setJoinOpen(true)
                    },
                });
            }
        }

        this.multiplayer.registerCustomEventHandler("scene", this)

        const verifyBelong = setInterval((x) => {
            let cameraStatus = false;
            let belongBillboardStatus = false;
            let positionStatus = false;

            if(this.belongExperience && this.multiplayer.userVideoRef) {
                cameraStatus = this.belongExperience.setupLocalTracks(this.multiplayer.userVideoRef);
            }

            if(this.belongBillboard && this.heightmap) {
                belongBillboardStatus = this.belongBillboard.setupHeightMap(this.heightmap);
            }

            if(cameraStatus && this.heightmap) {
                positionStatus = this.belongExperience.setupHeightMap(this.heightmap);
            }

            if(cameraStatus && positionStatus && belongBillboardStatus)
                clearInterval(verifyBelong);
        }, 1000);

        this.multiplayer?.updateOptions({
            positionalAudioEnabled: config?.positionalAudioEnabled || false,
            positionalAudioHelperEnabled: config?.positionalAudioHelperEnabled || false
        });

        // Initialise chat
        this.chat = new ChatClient();

        if (config.rooms)
        {
            config.rooms.forEach((def) =>
            {
                loader.load(def.url, (gltf) =>
                {
                    const room = gltf.scene;
                    room.name = "room";
                    room.visible = true;
                    room.castShadow = true;
                    room.receiveShadow = true;
                    this.scene.add(room);

                    if (def.ignoreRaycast)
                    {
                        room.traverse((x) =>
                        {
                            x.layers.disableAll();
                            x.layers.enable(RAYCAST_EXCLUDE_LAYER);
                        });
                    }

                    if (def.position)
                    {
                        room.position.fromArray(def.position);
                    }
                    if (def.scale)
                    {
                        room.scale.fromArray(def.scale);
                    }
                });

                if(def.collisionMeshUrl){
                    loader.load(def.collisionMeshUrl, (gltf) => {
                        const mesh = gltf.scene;
                        this.customCollisionGroup.add(mesh);
                    });
                }
            });


        }

        if (config.hdr)
        {
            //todo - sanity check lighting
            new HDREnvironment(this.scene, this.renderer, config.hdr.url);

            // const ambientLight = new THREE.AmbientLight(0xFFFFFF);
            // const ambientLightIntensity = 0.001;
            // this.scene.add(ambientLight,ambientLightIntensity);

            // const directionalLight = new THREE.AmbientLight(0xFFFFFF);
            // const directionalLightIntensity = 1.0;
            // this.scene.add(directionalLight, directionalLightIntensity);

            // const hemisphereLight = new THREE.HemisphereLight(0xFFFFFF);
            // const hemisphereLightSkyColor = 0x404040;
            // const hemisphereLightGroundColor = 0xFFFFFF;
            // const hemisphereLightIntensity = 1.0;
            // this.scene.add(hemisphereLight, hemisphereLightSkyColor, hemisphereLightGroundColor, hemisphereLightIntensity);

            // const pointLight = new THREE.PointLight(0xFFFFFF);
            // const pointLightIntensity = 1.0;
            // const pointLightDistance = 0;
            // this.scene.add(pointLight, pointLightIntensity, pointLightDistance );

            // const spotLight = new THREE.SpotLight(0xFFFFFF);
            // const spotLightIntensity = 1.0;
            // const spotLightDistance = 0;
            // const spotLightAngle = Math.PI/2;
            // this.scene.add(spotLight, spotLightIntensity, spotLightDistance, spotLightAngle);


        }


        if (config.heightmap)
        {
            let boundsMin, boundsMax, renderTextureSize;
            if (config.heightmap.boundsMin)
            {
                boundsMin = new THREE.Vector2().fromArray(config.heightmap.boundsMin);
            }
            if (config.heightmap.boundsMax)
            {
                boundsMax = new THREE.Vector2().fromArray(config.heightmap.boundsMax);
            }
            if (config.heightmap.renderTextureSize)
            {
                renderTextureSize = new THREE.Vector2().fromArray(config.heightmap.renderTextureSize);
            }
            this.heightmap = new Heightmap(this.scene, config.heightmap.url, this.camera, boundsMin, boundsMax, renderTextureSize);
        }

        if (config.pads)
        {
            config.pads.forEach((def) =>
            {

                if (def.padSrc)
                {
                    loader.load(def.padSrc, (gltf) =>
                    {
                        const padProp = gltf.scene;

                        if (def.padPropPosition)
                        {
                            padProp.position.fromArray(def.padPropPosition);
                        } else
                        {
                            padProp.position.fromArray(def.position);
                        }


                        if (def.padPropScale)
                        {
                            padProp.scale.fromArray(def.padPropScale);
                        }

                        if (def.padPropRotation)
                        {
                            const rotVec = new THREE.Vector3().fromArray(def.padPropRotation);
                            rotVec.multiplyScalar(THREE.MathUtils.DEG2RAD);
                            const rotation = new THREE.Euler().setFromVector3(rotVec);
                            padProp.rotation.copy(rotation);
                            padProp.updateMatrixWorld(true);
                        }

                        if (def.padPropRotateSpeed)
                        {
                            new ObjectRotator(padProp, def.padPropRotateSpeed);
                        } else
                        {
                            new ObjectRotator(padProp, 0.2);
                        }

                        this.scene.add(padProp);
                        padProp.traverse((obj) =>
                        {
                            obj.layers.disableAll();
                            obj.layers.enable(RAYCAST_EXCLUDE_LAYER);
                        });
                    });
                }


                const pad = new MovePad(
                    def.hero,
                    this.scene,
                    this.controls,
                    undefined,
                    def.scale,
                    undefined,
                    def.spriteImage,
                    undefined,
                    undefined,
                    def.hint,
                    def.overrideUrl,
                    def.padImage

                );
                pad.position = new THREE.Vector3().fromArray(def.position);
                pad.rotateTo = new THREE.Quaternion().fromArray(def.rotateTo);
                pad.rotateToViewEast = new THREE.Quaternion().fromArray(def.rotateToViewEast);
                pad.rotateToViewSouth = new THREE.Quaternion().fromArray(def.rotateToViewSouth);
                pad.rotateToViewWest = new THREE.Quaternion().fromArray(def.rotateToViewWest);
                if (def.offsetPosition)
                {
                    pad.offsetPosition = new THREE.Vector3().fromArray(def.offsetPosition);
                }
                if (App.isMobile)
                {
                    pad.clickableAreaScale = 2;
                }
                pad.moveInstant = def.instant || false;

            });
        }

        if (config.whiteboards)
        {
            // todo - fix Whiteboards - disabled on mobile because they seem to cause issues

            if (!App.isMobile)
            {
                config.whiteboards.forEach((def) =>
                {
                    const whiteboard = new Whiteboard({
                        uuid: def.uuid,
                        scene: this.scene,
                        images: ["assets/textures/whiteboard0.jpg"],
                        camera: this.camera,
                        renderer: this.renderer,
                        width: def.width,
                        height: def.height,
                        drawDistance: def.drawDistance,
                        lineWidth: def.lineWidth,
                        fontSize: def.fontSize,
                        onDrawStart: () => (this.controls.enabled = false),
                        onDrawStop: () => (this.controls.enabled = true),
                        onEnterTextMode: () =>
                        {
                            this.activeWhiteboard = whiteboard;
                            this.activeWhiteboardTextOpen = true;
                            this.controls.enabled = false;
                        },
                        onLeaveTextMode: () =>
                        {
                            this.activeWhiteboard = undefined;
                            this.activeWhiteboardTextOpen = false;
                            this.controls.enabled = true;
                        }
                    });

                    if (def.position)
                    {
                        whiteboard.setPosition(def.position[0], def.position[1], def.position[2]);
                    }
                    if (def.rotation)
                    {
                        whiteboard.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                    }

                    new WhiteboardControls(whiteboard, this.scene, def.buttonSize);

                    // if(def.postits){
                    //     def.postits.forEach((def1) => {
                    //         const postIt = new PostItNote(
                    //             def1.uuid,,
                    //             this.scene,
                    //             7.0,
                    //             0.8,
                    //             12.5,
                    //             0.0,
                    //             "assets/icons/whiteboard-icons-01.jpg",
                    //             "plane_gallery",
                    //             -180
                    //         );
                    //     })
                    // }
                });
            }
        }

        if (config.belong)
        {
            if (!App.isMobile)
            {
                config.belong.forEach((e) =>
                {
                    const def = e.welcome;
                    const belongWelcome = new BelongWelcome({
                        uuid: def.uuid,
                        scene: this.scene,
                        images: ["assets/textures/whiteboard0.jpg"],
                        camera: this.camera,
                        renderer: this.renderer,
                        width: def.width,
                        height: def.height,
                        activeDistance: def.activeDistance,
                        onEnterTextMode: (mode) =>
                        {
                            this.activePhotobooth = belongWelcome;
                            this.photoboothTextOpen = mode === "Acceptance";
                            this.photoboothCountryOpen = mode === "Country";
                            this.controls.enabled = false;
                        },
                        onLeaveTextMode: () =>
                        {
                            this.activePhotobooth = undefined;
                            this.photoboothTextOpen = false;
                            this.photoboothCountryOpen = false;
                            this.controls.enabled = true;
                        }
                    });

                    const billboardDef = e.billboard;
                    const belongBillboard = new BelongBillboard({
                        uuid: billboardDef.uuid,
                        scene: this.scene,
                        images: ["assets/textures/whiteboard0.jpg"],
                        camera: this.camera,
                        renderer: this.renderer,
                        width: billboardDef.width,
                        height: billboardDef.height,
                        activeDistance: billboardDef.activeDistance
                    });

                    setTimeout(() => {
                        const interactiveMapDef = e.interactiveMap;
                        const belongInteractiveMapDef = new BelongInteractiveMap({
                            uuid: interactiveMapDef.uuid,
                            scene: this.scene,
                            images: ["assets/textures/whiteboard0.jpg"],
                            camera: this.camera,
                            renderer: this.renderer,
                            width: interactiveMapDef.width,
                            height: interactiveMapDef.height,
                            activeDistance: interactiveMapDef.activeDistance
                        });

                        if (interactiveMapDef.position)
                        {
                            // TODO: Uncomment when done developing
                            belongInteractiveMapDef.setPosition(interactiveMapDef.position[0], interactiveMapDef.position[1], interactiveMapDef.position[2]);

                            // belongInteractiveMapDef.setPosition(-10, 4, 10)
                        }
                        if (interactiveMapDef.rotation)
                        {
                            // TODO: Uncomment when done developing
                            belongInteractiveMapDef.setRotation(interactiveMapDef.rotation[0], interactiveMapDef.rotation[1], interactiveMapDef.rotation[2]);

                            // belongInteractiveMapDef.setRotation(0, 0, 0)
                        }
                    }, 3000);

                    if (def.position)
                    {
                        // TODO: Uncomment when done developing
                        belongWelcome.setPosition(def.position[0], def.position[1], def.position[2]);
                        // belongWelcome.setPosition(-10, 1.5, 8)
                    }
                    if (def.rotation)
                    {
                        // TODO: Uncomment when done developing
                        belongWelcome.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                        // belongWelcome.setRotation(0, 0, 0)
                    }

                    if (billboardDef.position)
                    {
                        // TODO: Uncomment when done developing
                        belongBillboard.setPosition(billboardDef.position[0], billboardDef.position[1], billboardDef.position[2]);
                        // belongBillboard.setPosition(-10, 1.5, 8)
                    }
                    if (billboardDef.rotation)
                    {
                        // TODO: Uncomment when done developing
                        belongBillboard.setRotation(billboardDef.rotation[0], billboardDef.rotation[1], billboardDef.rotation[2]);
                        // belongBillboard.setRotation(0, 0, 0)
                    }

                    const sculptureDef = e.sculpture;

                    const belongService = new BelongService();
                    belongService.getPicturesAsync(BELONG_MAX_PICTURE_LIMIT).then((imgs) => {
                        const limit = imgs.length > BELONG_MAX_PICTURE_LIMIT ? BELONG_MAX_PICTURE_LIMIT : imgs.length;

                        for(let i = 0; i < limit; i++) {
                            const img = imgs[i];
                            const position = sculptureDef.positions[i];
                            const s = new BelongPicture({
                                uuid: `${sculptureDef.uuid}-${img.pk}`,
                                scene: this.scene,
                                payload: img.data,
                                images: ["assets/textures/whiteboard0.jpg"],
                                camera: this.camera,
                                renderer: this.renderer,
                                width: sculptureDef.width,
                                height: sculptureDef.height
                            });

                            s.setPosition(position.position[0], position.position[1], position.position[2])
                            s.setRotation(position.rotation[0], position.rotation[1], position.rotation[2])
                            this.belongPictures.push(s);
                        }
                    });



                    setInterval(() => {
                        if (this.heightmap && this.heightmap.playerPos) {
                            const distance = Math.sqrt(Math.pow(100 - this.heightmap.playerPos.x, 2) + Math.pow((3 * -1) - this.heightmap.playerPos.y, 2));

                            if (distance > 5 && this.inRange) {
                                this.inRange = false;
                            } else if (distance <= 5 && !this.inRange) {
                                this.inRange = true;
                            }
                        }
                    }, 250);


                    setInterval(() => {
                        if(this.inRange) {
                            belongService.getPicturesAsync(BELONG_MAX_PICTURE_LIMIT).then((imgs) => {
                                for(let i = 0; i < this.belongPictures.length; i++) {
                                    this.belongPictures[i].setup(imgs[i].data);
                                }

                                if(this.belongPictures.length < BELONG_MAX_PICTURE_LIMIT && imgs.length > this.belongPictures.length) {
                                    const limit = imgs.length > BELONG_MAX_PICTURE_LIMIT ? BELONG_MAX_PICTURE_LIMIT : imgs.length;

                                    for(let i = this.belongPictures.length; i < limit; i++) {
                                        const img = imgs[i];
                                        const position = sculptureDef.positions[i];
                                        const s = new BelongPicture({
                                            uuid: `${sculptureDef.uuid}-${img.pk}`,
                                            scene: this.scene,
                                            payload: img.data,
                                            images: ["assets/textures/whiteboard0.jpg"],
                                            camera: this.camera,
                                            renderer: this.renderer,
                                            width: sculptureDef.width,
                                            height: sculptureDef.height
                                        });

                                        s.setPosition(position.position[0], position.position[1], position.position[2])
                                        s.setRotation(position.rotation[0], position.rotation[1], position.rotation[2])
                                        this.belongPictures.push(s);
                                    }
                                }
                            });
                        }
                    }, BELONG_PICTURE_REFRESH_INTERVAL)

                    this.belongExperience = belongWelcome;
                    this.belongBillboard = belongBillboard;
                });
            }
        }

        if (config.slideshows)
        {
            config.slideshows.forEach((def) =>
            {
                const imageViewer = new ImageViewer(this.renderer, def.uuid, this.scene, def.images, def.width, def.height, def.offsetControlPosition);

                if (def.position)
                {
                    imageViewer.setPosition(def.position[0], def.position[1], def.position[2]);
                }
                if (def.rotation)
                {
                    imageViewer.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.avatarBuilder)
        {
            config.avatarBuilder.forEach((def) =>
            {
                const avatarBuilder = new AvatarBuilder(this.renderer, def.uuid, this.scene, def.items, def.width, def.height, def.controlsOffset);

                if (def.position)
                {
                    avatarBuilder.setPosition(def.position[0], def.position[1], def.position[2]);
                }
                if (def.rotation)
                {
                    avatarBuilder.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.imageViewerNFT)
        {
            config.imageViewerNFT.forEach((def) =>
            {
                const imageViewerNFT = new ImageViewerNFT(this.renderer, def.uuid, this.scene, def.items, def.width, def.height);

                if (def.position)
                {
                    imageViewerNFT.setPosition(def.position[0], def.position[1], def.position[2]);
                }

                if (def.rotation)
                {
                    imageViewerNFT.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.imageViewerNFTsimple)
        {
            config.imageViewerNFTsimple.forEach((def) =>
            {
                const imageViewerNFTsimple = new ImageViewerNFTsimple(this.renderer, def.uuid, this.scene, def.items, def.width, def.height);

                if (def.position)
                {
                    imageViewerNFTsimple.setPosition(def.position[0], def.position[1], def.position[2], def.position[3], def.position[4], def.position[5]);
                }
                if (def.rotation)
                {
                    imageViewerNFTsimple.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.imageViewerNFTsimpleMob)
        {
            config.imageViewerNFTsimpleMob.forEach((def) =>
            {
                const imageViewerNFTsimpleMob = new ImageViewerNFTsimpleMob(this.renderer, def.uuid, this.scene, def.items, def.width, def.height);

                if (def.position)
                {
                    imageViewerNFTsimpleMob.setPosition(def.position[0], def.position[1], def.position[2], def.position[3], def.position[4], def.position[5]);
                }
                if (def.rotation)
                {
                    imageViewerNFTsimpleMob.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.imageViewerNFTsimpleBio)
        {
            config.imageViewerNFTsimpleBio.forEach((def) =>
            {
                const imageViewerNFTsimpleBio = new ImageViewerNFTsimpleBio(this.renderer, def.uuid, this.scene, def.items, def.width, def.height);

                if (def.position)
                {
                    imageViewerNFTsimpleBio.setPosition(def.position[0], def.position[1], def.position[2], def.position[3], def.position[4], def.position[5]);
                }
                if (def.rotation)
                {
                    imageViewerNFTsimpleBio.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.imageViewerNFTsimpleInfo)
        {
            config.imageViewerNFTsimpleInfo.forEach((def) =>
            {
                const imageViewerNFTsimpleInfo = new ImageViewerNFTsimpleInfo(this.renderer, def.uuid, this.scene, def.items, def.width, def.height);

                if (def.position)
                {
                    imageViewerNFTsimpleInfo.setPosition(def.position[0], def.position[1], def.position[2], def.position[3], def.position[4], def.position[5]);
                }
                if (def.rotation)
                {
                    imageViewerNFTsimpleInfo.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.imageViewerNFTsimpleInfoZone)
        {
            config.imageViewerNFTsimpleInfoZone.forEach((def) =>
            {
                const imageViewerNFTsimpleInfoZone = new ImageViewerNFTsimpleInfoZone(this.renderer, def.uuid, this.scene, def.items, def.width, def.height);

                if (def.position)
                {
                    imageViewerNFTsimpleInfoZone.setPosition(def.position[0], def.position[1], def.position[2], def.position[3], def.position[4], def.position[5]);
                }
                if (def.rotation)
                {
                    imageViewerNFTsimpleInfoZone.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }
        if (config.imageViewerNFTsimpleMovie)
        {
            config.imageViewerNFTsimpleMovie.forEach((def) =>
            {
                const imageViewerNFTsimpleMovie = new ImageViewerNFTsimpleMovie(this.renderer, def.uuid, this.scene, def.items, def.width, def.height);

                if (def.position)
                {
                    imageViewerNFTsimpleMovie.setPosition(def.position[0], def.position[1], def.position[2], def.position[3], def.position[4], def.position[5]);
                }
                if (def.rotation)
                {
                    imageViewerNFTsimpleMovie.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.imageViewerNFTsimpleBioJoin)
        {
            config.imageViewerNFTsimpleBioJoin.forEach((def) =>
            {
                const imageViewerNFTsimpleBioJoin = new ImageViewerNFTsimpleBioJoin(this.renderer, def.uuid, this.scene, def.items, def.width, def.height);

                if (def.position)
                {
                    imageViewerNFTsimpleBioJoin.setPosition(def.position[0], def.position[1], def.position[2], def.position[3], def.position[4], def.position[5]);
                }
                if (def.rotation)
                {
                    imageViewerNFTsimpleBioJoin.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.imageViewerShop)
        {
            config.imageViewerShop.forEach((def) =>
            {
                const imageViewerShop = new ImageViewerShop(this.renderer, def.uuid, this.scene, def.items, def.width, def.height);

                if (def.position)
                {
                    imageViewerShop.setPosition(def.position[0], def.position[1], def.position[2]);
                }

                if (def.rotation)
                {
                    imageViewerShop.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.pdfs)
        {
            config.pdfs.forEach((def) =>
            {

                if (def.src)
                {
                    loader.load(def.src, (gltf) =>
                    {
                        const pdfProp = gltf.scene;

                        if (def.propPosition)
                        {
                            pdfProp.position.fromArray(def.propPosition);
                        }
                        if (def.propScale)
                        {
                            pdfProp.scale.fromArray(def.propScale);
                        }

                        if (def.propRotation)
                        {
                            const rotVec = new THREE.Vector3().fromArray(def.propRotation);
                            rotVec.multiplyScalar(THREE.MathUtils.DEG2RAD);
                            const rotation = new THREE.Euler().setFromVector3(rotVec);
                            pdfProp.rotation.copy(rotation);
                            pdfProp.updateMatrixWorld(true);
                        }

                        if (def.propRotateSpeed)
                        {
                            new ObjectRotator(pdfProp, def.propRotateSpeed);
                        }

                        this.scene.add(pdfProp);
                        pdfProp.traverse((obj) =>
                        {
                            obj.layers.disableAll();
                            obj.layers.enable(RAYCAST_EXCLUDE_LAYER);
                        });
                    });
                }


                const trigger = new ModalTriggerPDF(this.scene, () => window.open(def.pdf, "_blank"), def.imagePDF);
                trigger.setPositionFromArray(def.position);
                trigger.setRotation(def.rotation);
                trigger.setScale(def.scale);
            });
        }

        if (config.urls)
        {
            config.urls.forEach((def) =>
            {

                if (def.src)
                {
                    loader.load(def.src, (gltf) =>
                    {
                        const urlProp = gltf.scene;

                        if (def.propPosition)
                        {
                            urlProp.position.fromArray(def.propPosition);
                        }
                        if (def.propScale)
                        {
                            urlProp.scale.fromArray(def.propScale);
                        }

                        if (def.propRotation)
                        {
                            const rotVec = new THREE.Vector3().fromArray(def.propRotation);
                            rotVec.multiplyScalar(THREE.MathUtils.DEG2RAD);
                            const rotation = new THREE.Euler().setFromVector3(rotVec);
                            urlProp.rotation.copy(rotation);
                            urlProp.updateMatrixWorld(true);
                        }

                        if (def.propRotateSpeed)
                        {
                            new ObjectRotator(urlProp, def.propRotateSpeed);
                        }

                        this.scene.add(urlProp);
                        urlProp.traverse((obj) =>
                        {
                            obj.layers.disableAll();
                            obj.layers.enable(RAYCAST_EXCLUDE_LAYER);
                        });
                    });
                }


                const trigger = new ModalTriggerURL(this.scene, () => window.open(def.url, "_blank"), def.imageURL);
                trigger.setPositionFromArray(def.position);
                trigger.setRotation(def.rotation);
                trigger.setScale(def.scale);
            });
        }

        if (config.videos)
        {
            config.videos.forEach((def) =>
            {
                const root = document.getElementById("root");

                const element = document.createElement("video");
                element.id = def.uuid;
                element.style.display = "none";
                element.playsInline = true;
                element.loop = true;
                element.crossOrigin = "anomynous";
                element.src = def.src;

                root.appendChild(element);

                const videoViewer = new VideoViewer(def.uuid, this.scene, this.listener, def.poster, def.width, def.height);
                if (def.position)
                {
                    videoViewer.setPosition(def.position[0], def.position[1], def.position[2]);
                }

                if (def.rotation)
                {
                    videoViewer.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.videos_custom)
        {
            config.videos_custom.forEach((def) =>
            {
                const root = document.getElementById("root");

                const element = document.createElement("video");
                element.id = def.uuid;
                element.style.display = "none";
                element.playsInline = true;
                element.playsInline = true;
                element.crossOrigin = "anomynous";
                // element.loop = true;
                element.src = def.src;

                root.appendChild(element);

                const videoViewer = new VideoViewerCustom(def.uuid, this.scene, this.listener, def.poster, def.modelUrl, def.videoFaceName, def.yRot, def.voting);
                if (def.position)
                {
                    videoViewer.setPosition(def.position[0], def.position[1], def.position[2]);
                }
                if (def.rotation)
                {
                    videoViewer.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
            });
        }

        if (config.p5)
        {
            config.p5.forEach((def) =>
            {

                let sketch;

                switch (def.sketchId)
                {
                    case "Example17":
                    {
                        sketch = new ExampleSketch17();
                        break;
                    }
                    case "Example16":
                    {
                        sketch = new ExampleSketch16();
                        break;
                    }
                    case "Example15":
                    {
                        sketch = new ExampleSketch15();
                        break;
                    }
                    case "Example14":
                    {
                        sketch = new ExampleSketch14();
                        break;
                    }
                    case "Example13":
                    {
                        sketch = new ExampleSketch13();
                        break;
                    }
                    case "Example12":
                    {
                        sketch = new ExampleSketch12();
                        break;
                    }
                    case "Example11":
                    {
                        sketch = new ExampleSketch11();
                        break;
                    }
                    case "Example10":
                    {
                        sketch = new ExampleSketch10();
                        break;
                    }
                    case "Example9":
                    {
                        sketch = new ExampleSketch9();
                        break;
                    }
                    case "Example8":
                    {
                        sketch = new ExampleSketch8();
                        break;
                    }
                    case "Example7":
                    {
                        sketch = new ExampleSketch7();
                        break;
                    }
                    case "Example6":
                    {
                        sketch = new ExampleSketch6();
                        break;
                    }
                    case "Example5":
                    {
                        sketch = new ExampleSketch5();
                        break;
                    }
                    case "Example4":
                    {
                        sketch = new ExampleSketch4();
                        break;
                    }
                    case "Example3":
                    {
                        sketch = new ExampleSketch3();
                        break;
                    }
                    case "Example2":
                    {
                        sketch = new ExampleSketch2();
                        break;
                    }
                    default:
                    {
                        sketch = new ExampleSketch();
                        break;
                    }
                }

                const p5Object = new P5Object(def.uuid, this.scene, def.modelUrl, def.videoFaceName, sketch);
                if (def.position)
                {
                    p5Object.setPosition(def.position[0], def.position[1], def.position[2]);
                }
                if (def.rotation)
                {
                    p5Object.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }


            });
        }

        if (config.videosGreenscreen)
        {
            config.videosGreenscreen.forEach((def) =>
            {
                const root = document.getElementById("root");

                const element = document.createElement("video");
                element.id = def.uuid;
                element.style.display = "none";
                element.playsInline = true;
                element.crossOrigin = "anomynous";
                element.loop = true;
                element.src = def.src;

                root.appendChild(element);

                const videoViewerGreenscreen = new VideoViewerGreenscreen(def.uuid, this.scene, this.listener, def.poster, def.width, def.height, def.controloffset, def.controlpushforward, def.beginpaused);
                if (def.position)
                {
                    videoViewerGreenscreen.setPosition(def.position[0], def.position[1], def.position[2], def.position[3], def.position[4]);
                }
                if (def.rotation)
                {
                    videoViewerGreenscreen.setRotation(def.rotation[0], def.rotation[1], def.rotation[2]);
                }
                // if (def.visibility) videoViewerGreenscreen.setPosition(def.position[0], def.position[1], def.position[2]);
            });
        }

        if (config.draggables)
        {
            config.draggables.forEach((def) =>
            {
                //
                loader.load(def.src, (gltf) =>
                {
                    const geometry = new THREE.BoxGeometry(1.25, 1.25, 1.25);
                    const sphere = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({
                        opacity: 0.0, transparent: true
                    }));

                    const draggableSculpture = gltf.scene;
                    draggableSculpture.scale.setScalar(1.0);
                    draggableSculpture.rotateY(-Math.PI / 0.8);
                    draggableSculpture.traverse((obj) =>
                    {
                        obj.layers.disableAll();
                        obj.layers.enable(RAYCAST_EXCLUDE_LAYER);
                    });

                    sphere.add(draggableSculpture);
                    sphere.position.fromArray(def.position);
                    if (def.rotation)
                    {
                        sphere.rotation.copy(convertFromEulerArray(def.rotation));
                    }
                    if (def.scale)
                    {
                        sphere.scale.fromArray(def.scale);
                    }
                    this.scene.add(sphere);

                    new DraggableObject(def.uuid, sphere, this.camera);
                });
            });
        }

        if (config.props)
        {
            config.props.forEach((def) =>
            {
                //
                loader.load(def.src, (gltf) =>
                {

                    const prop = gltf.scene;

                    if (def.position)
                    {
                        prop.position.fromArray(def.position);
                    }
                    if (def.scale)
                    {
                        prop.scale.fromArray(def.scale);
                    }

                    if (def.rotation)
                    {
                        const rotVec = new THREE.Vector3().fromArray(def.rotation);
                        rotVec.multiplyScalar(THREE.MathUtils.DEG2RAD);
                        // const rotation = new THREE.Euler((def.rotation[0] || 0) * THREE.MathUtils.DEG2RAD, (def.rotation[1] || 0) * THREE.MathUtils.DEG2RAD, (def.rotation[2] || 0) * THREE.MathUtils.DEG2RAD);
                        const rotation = new THREE.Euler().setFromVector3(rotVec);
                        prop.rotation.copy(rotation);
                        prop.updateMatrixWorld(true);
                    }

                    if (def.rotateSpeed)
                    {
                        new ObjectRotator(prop, def.rotateSpeed);
                    }

                    this.scene.add(prop);
                });
            });
        }

        if (config.screenshare)
        {
            const def = config.screenshare;

            const screen = new ScreenObject("screen-share", this.scene, def.width, def.height);
            screen.board.position.fromArray(def.position);
            screen.board.rotation.y = THREE.MathUtils.degToRad(def.rotY);

            // screen.board.visible = false;
            //debug mode made it v:
            screen.board.visible = true;

            this.multiplayer.onRemoteUserStartScreenShare?.on(() =>
            {
                screen.board.visible = true;
            });

            this.multiplayer.onRemoteUserStopScreenShare?.on(() => {
                screen.board.visible = false;
            });
        }

        if (config.liveStreamScreen) {
            const def = config.liveStreamScreen;

            const liveStreamScreen = new LiveStreamScreen(LIVE_STREAM_SCREEN_ID, this.scene, this.listener,  def.width, def.height, def.poster);

            liveStreamScreen.setPosition(new THREE.Vector3().fromArray(def.position))
            liveStreamScreen.screen.rotation.y = THREE.MathUtils.degToRad(def.rotY);

            this.liveStreamClient.setPositionalAudio(liveStreamScreen.positionalAudio);
            this.liveStreamClient.join(def.channelId);
        }
        if (config.liveStreamScreenB) {
            const def = config.liveStreamScreenB;

            const liveStreamScreenB = new LiveStreamScreenB(LIVE_STREAM_SCREEN_ID_B, this.scene, this.listener,  def.width, def.height);

            liveStreamScreenB.setPosition(new THREE.Vector3().fromArray(def.position))
            liveStreamScreenB.screen.rotation.y = THREE.MathUtils.degToRad(def.rotY);

            this.liveStreamClientB.setPositionalAudio(liveStreamScreenB.positionalAudio);
            this.liveStreamClientB.join(def.channelId);
        }

        if(config.speeddating){
            const def = config.speeddating;
            const client = new SpeedDatingClient({
                scene: this,
                buttonPosition: new THREE.Vector3().fromArray(def.buttonPosition),
                buttonScale: new THREE.Vector3().fromArray(def.buttonScale),
                returnButtonPosition: new THREE.Vector3().fromArray(def.returnButtonPosition),
                returnButtonScale: new THREE.Vector3().fromArray(def.returnButtonScale),
                teleportPositionA: new THREE.Vector3().fromArray(def.teleportPositionA),
                teleportPositionB: new THREE.Vector3().fromArray(def.teleportPositionB),
                teleportRotationA: convertFromEulerArray(def.teleportRotationA),
                teleportRotationB: convertFromEulerArray(def.teleportRotationB),
                returnTeleportPosition: new THREE.Vector3().fromArray(def.returnTeleportPosition),
                returnTeleportRotation: convertFromEulerArray(def.returnTeleportRotation),
            });
        }
    };

    raycast = () => {
        this.raycaster.setFromCamera(this.mouse, this.camera);
        const intersects = this.raycaster.intersectObjects(this.scene.children, true); //array
        return intersects;
    }

    setupLogic = () => {
        const handleClickEvent = () => {
            this.controls.targetRotation = null; // if we are rotating to a movepad target, stop rotating
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const intersects = this.raycaster.intersectObjects(this.scene.children, true); //array
            if (intersects.length > 0 && intersects[0]) {
                const clickable = Clickable.clickables.get(intersects[0].object.uuid);
                if (clickable) clickable.handleClicked(intersects[0]);
            }
        };

        let storedDraggable = null;

        const handlePointerDownEvent = (e) => {
            this.raycaster.setFromCamera(this.mouse, this.camera);
            const intersects = this.raycaster.intersectObjects(this.scene.children, true);
            if (intersects.length > 0 && intersects[0]) {
                const draggable = Draggable.draggables.get(intersects[0].object.uuid);
                if (draggable) {
                    e.stopPropagation();
                    //todo - chris needed to disable this - possibly an oversight
                    // whiteboard.isDrawing = false;
                    this.dragging = true;
                    storedDraggable = draggable;
                }
            }
        };

        // todo - check back on this - currently disabled as we are not using postits - also! - it seems to block some movement of avatar
        // using a plane to project the drags onto. This is not ideal and could be more
        // performant but it'll do for prototype stage
        const planes = {};

        // if (whiteboardsOn) {
        // function addPlane(id, x, y, z, w, h, r) {
            // const geo = new THREE.PlaneBufferGeometry(w, h);
            // planes[id] = new THREE.Mesh(geo, new THREE.MeshBasicMaterial({ color: "red", visible: false }));
            // planes[id].position.set(x, y, z);
            // planes[id].rotateY(r * THREE.MathUtils.DEG2RAD);
        // }

        // addPlane("plane_gallery", 7.0, 1.2, 12.55, 2, 2, 180);
        // addPlane("plane_speaker", -14.99, 1.2, 2.8, 2, 2, 90);
        // addPlane("plane_lungroom", -26.1, 1.2, -10.5, 2, 2, 0);
        // addPlane("plane_lastroom", -43.0, 2.0 + 1.2, 16.1, 2, 2, 118);

        // for (const key in planes) {
            // this.scene.add(planes[key]); // for some reason raycaster only works if this is in the scene
        // }

        const checkDraggableIntersection = (raycaster) => {
            if (storedDraggable) {
                if (storedDraggable.unrestrictred) {
                    const intersects = raycaster.intersectObject(planes[storedDraggable.meta.planeId], true);
                    if (intersects.length > 0 && intersects[0]) {
                        const pos = intersects[0].point;
                        storedDraggable.handleDragged({
                            newPos: pos,
                            r: planes[storedDraggable.meta.planeId].rotation,
                        });
                    }
                } else {
                    let pos = new THREE.Vector3();

                    const maxDistance = 2.5;

                    const intersects = raycaster.intersectObjects(this.scene.children, true);
                    if (intersects.length > 0 && intersects.length > 0) {
                        if (intersects.length > 1) {
                            pos = intersects[1].point;
                            if (pos.distanceTo(this.camera.position) > maxDistance) {
                                const ray = raycaster.ray;
                                ray.at(maxDistance, pos);
                            }
                        } else {
                            const ray = raycaster.ray;
                            ray.at(maxDistance, pos);
                        }
                    }

                    storedDraggable.handleDragged(pos);
                }
            }
        };

        const checkHoverables = (raycaster) => {
            document.body.style.cursor = "default";

            const hovering = Array.from(Hoverable.hoverables)
                .map(([_name, value]) => value)
                .filter((x) => x.hovered);

            const intersects = raycaster.intersectObjects(this.scene.children, true);

            // Check if existing hovered items match anything in the intersections. If not then mark them as unhovered.
            hovering.forEach((x) => {
                if (!intersects.find((y) => y.object === x.object)) {
                    x.handleUnhover();
                }
            });

            for (let i = 0; i < intersects.length; i++) {
                const intersect = intersects[i];
                if (i > 1) return; // NOTE: the whiteboards always seem to be the 2nd item
                const hoverable = Hoverable.hoverables.get(intersect.object.uuid);
                if (hoverable) {
                    hoverable.handleHovered(intersect);
                }
            }
        };

        const updateMouse = (e, x, y) => {
            this.mouse.x = (x / window.innerWidth) * 2 - 1;
            this.mouse.y = -(y / window.innerHeight) * 2 + 1;

            this.raycaster.setFromCamera(this.mouse, this.camera);
            checkHoverables(this.raycaster);

            if (!this.dragging) return;

            e.stopPropagation();

            //todo - chris needed to disable this - possibly an oversight
            // whiteboard.isDrawing = false;
            this.raycaster.setFromCamera(this.mouse, this.camera);
            checkDraggableIntersection(this.raycaster);
        };

        const handlePointerMoveEvent = (e) => {
            updateMouse(e, e.clientX, e.clientY);
        };

        const handleTouchMoveEvent = (e) => {
            const ex = e.changedTouches[0].pageX;
            const ey = e.changedTouches[0].pageY;
            updateMouse(e, ex, ey);
        };

        const handlePointerUpEvent = () => {
            this.dragging = false;
        };

        const handleTouchStartEvent = (e) => {
            const ex = e.changedTouches[0].pageX;
            const ey = e.changedTouches[0].pageY;
            updateMouse(e, ex, ey);
            handleClickEvent();
        };

        this.renderer.domElement.addEventListener("click", handleClickEvent, true);
        this.renderer.domElement.addEventListener("touchstart", handleTouchStartEvent, true);
        this.renderer.domElement.addEventListener("pointerdown", handlePointerDownEvent, true);
        this.renderer.domElement.addEventListener("pointermove", handlePointerMoveEvent, true);
        this.renderer.domElement.addEventListener("touchmove", handleTouchMoveEvent, false);
        this.renderer.domElement.addEventListener("pointerup", handlePointerUpEvent, true);
        this.renderer.domElement.addEventListener("touchend", handlePointerUpEvent, true);
    };
}


function convertFromEulerArray(rotationArr){
   return new THREE.Euler(
       (rotationArr[0] || 0) * THREE.MathUtils.DEG2RAD,
       (rotationArr[1] || 0) * THREE.MathUtils.DEG2RAD,
        (rotationArr[2] || 0) * THREE.MathUtils.DEG2RAD
   )
}
